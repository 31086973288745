import { memo, useEffect, useRef, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "@platformAssets/components/Select.module.scss";

const Select = ({
  name,
  handleChange,
  value = null,
  values = {},
  id = null,
  className = null,
  customClassList = null,
  placeholder = null,
  animatePlaceholder = false,
  autoComplete = "off",
  disabled = false,
  errorText = null,
  warningText = null,
  error = false,
  reverse = false,
  order = null,
}) => {
  const [toggleList, setToggleList] = useState(false);
  const dropDownWrapRef = useRef(null);
  const dropDownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  /**
   * Hide current list if user click outside of element
   * @param {Object} event
   */
  const handleClickOutside = (event) => {
    if (!dropDownRef?.current || !dropDownWrapRef?.current) {
      return;
    }

    if (dropDownWrapRef?.current.contains(event.target)) {
      return;
    }

    setToggleList(false);
  };

  /**
   * Set selected value
   * @param {*} item
   */
  const handleSetValue = (item) => {
    if (typeof handleChange === "function") {
      handleChange(item);
    }

    setToggleList(false);
  };

  /**
   * Open|close dropdown list
   */
  const handleToggleSelect = () => {
    setToggleList(!toggleList);
  };

  /**
   * Ge list of items for dropdown l;ist
   * @param {*} selected Selected value
   * @param {Object} values Selected value
   * @param {boolean} reverse reverse obj bool
   * @returns {*[]}
   */
  const getListItems = (selected = null, values = {}, reverse = false) => {
    const content = [];

    let iteration = 0;
    for (const [key, label] of Object.entries(values)) {
      content.push(
        <li
          onClick={() => handleSetValue(key)}
          key={`d_${name}_${iteration}`}
          className={cn(customClassList, {
            [styles.selectListItemSelected]: selected === key,
          })}
        >
          {label}
        </li>
      );

      iteration++;
    }

    if (reverse) {
      return content.reverse();
    }

    if (Array.isArray(order)) {
      return order.map((index) => content[index]);
    }

    return content;
  };

  const dropDownContent = getListItems(value, values, reverse);

  return (
    <>
      <div
        className={cn(styles.inputWrapper, className, {
          [styles.inputAnimated]: animatePlaceholder,
        })}
        ref={dropDownWrapRef}
      >
        <input
          className={cn({
            [styles.disabled]: disabled && !error,
            [styles.errorDisabled]: disabled && error,
            [styles.error]: (error || errorText) && !disabled,
          })}
          type="text"
          name={name}
          id={id ?? `${name}Id`}
          autoComplete={autoComplete}
          placeholder={placeholder ?? ""}
          value={value}
          disabled={disabled}
          onClick={handleToggleSelect}
          readOnly={true}
        />
        {animatePlaceholder && (
          <label
            htmlFor={id ?? `${name}Id`}
            className={styles.inputAnimatedPlaceholder}
          >
            {placeholder}
          </label>
        )}
        <p className={styles.errorText}>{errorText}</p>
        <p className={styles.errorWarning}>{warningText}</p>
        {toggleList && (
          <div className={styles.selectListWrap}>
            <ul ref={dropDownRef} className={styles.selectList}>
              {dropDownContent}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  values: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  animatePlaceholder: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  warningText: PropTypes.string,
  error: PropTypes.bool,
  reverse: PropTypes.bool,
};

export default memo(Select);
